import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
export const query = graphql`
  query {
    katalog1: file(
      relativePath: { eq: "katalog-produktow-crm-zarzadzanie.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 85) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

const Zarzadzaniekatalogiem = ({ data }) => {
  return (
    <FunctionPageLayout
      title="Baza produktów i usług w CRM"
      headline="Zarządzanie katalogiem produktów i cennikami"
    >
      <SEO
        title="Zarządzanie katalogiem produktów i cennikami w CRM"
        description="System CRM ✅ Katalog produktów • Indywidualne cenniki dla kontrahentów • Zapytaj o bezpłatne demo!"
        keywords={["zarządzanie katalogiem produktów i cennikami"]}
      />
      <p>
        System CRM umożliwia zbudowanie{" "}
        <strong>
          wspólnej, szczegółowej bazy wiedzy o produktach i usługach.
        </strong>
        Zintegrowana baza produktów z informacją o <strong>cenach</strong>, czy{" "}
        <strong>stanie magazynowym</strong> pośrednio dba o spójność komunikacji
        z klientem. Obsługa klienta staje się{" "}
        <strong>uporządkowana i profesjonalna</strong>. Synergius CRM daje
        również{" "}
        <strong>możliwość zarządzania polityką cenową i rabatową</strong>, tak
        aby odwzorować{" "}
        <strong>indywidualne warunki sprzedaży dla każdego klienta</strong> i
        zapewnić ich przestrzeganie.
      </p>
      <br />
      <p>
        CRM dostarczy Twoim pracownikom przejrzystą bazę produktów. Drzewo
        kategorii oraz widoczne kolumny{" "}
        <strong>dostosujesz samodzielnie do swoich potrzeb</strong>. Dodatkowo{" "}
        <strong>możesz dodawać własne atrybuty do produktów</strong>.
      </p>
      <p>
        <strong>Oferty</strong>, tak jak inne dokumenty w systemie CRM
        (zamówienia, faktury) pracownicy będą mogli tworzyć z wykorzystaniem
        bazy produktów. W ten sposób zachowasz spójność ofertowania.
      </p>
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.katalog1]}
        alts={[
          "ofertowanie  cennik katalog zarządzanie katalogiem produktów i cennikami system crm",
        ]}
      />
      <br />
      <h4>
        <strong>
          Zarządzanie katalogiem produktów i cennikami — najważniejsze funkcje
        </strong>
      </h4>
      <p>
        <ul>
          <li>Dodawanie / usuwanie / edycja kart produktów i usług </li>
          <li>Tworzenie dowolnych opisów dla produktów i usług</li>
          <li>Tworzenie dowolnych cech / atrybutów dla produktów i usług</li>
          <li>
            Zaawansowane wyszukiwanie produktów wg podstawowych kryteriów oraz
            wg dowolnych cech / atrybutów
          </li>
          <li>Dodawanie dowolnej liczby zdjęć do produktów</li>
          <li>Dodawanie dowolnych plików do produktów</li>
          <li>Definiowanie i zarządzanie kategoriami produktów</li>
          <li>Definiowanie własnych szablonów dla kart produktów</li>
          <li>Import produktów z pliku XSLX</li>
          <li>
            Definiowanie indywidualnych cen dla poszczególnych kontrahentów
          </li>
          <li>
            Definiowanie rabatów procentowych na wybrane kategorie towarowe dla
            poszczególnych kontrahentów
          </li>
          <li>
            Definiowanie czasu obowiązywania indywidualnych cenników / rabatów
          </li>
          <li>Oznaczanie produktów promowanych</li>
        </ul>
      </p>
    </FunctionPageLayout>
  );
};

export default Zarzadzaniekatalogiem;
